<template>
  <div class="my-page-news my-page wider" :class="{skeleton : !state.loaded }">
    <ul class="tight font-sm">
      <template v-if="state.news.length">
        <li v-for="(n, idx) in state.news" :key="idx" @click="open(n, $event)">
          <span class="picture" @click.stop>
          <router-link :to="`/${n.projectType === 'invest' ? 'invest' : 'reward'}/${n.projectSeq}`">
            <span class="img" :style="{backgroundImage: `url('${n.thumbFilePath}')`}"></span>
          </router-link>
          </span>
          <div class="wrapper">
            <div class="project bold">
              <span class="badge badge-secondary">{{ n.projectTypeName }}</span>
              <span class="name font-sm">{{ n.projectName }} </span>
            </div>
            <div class="title">
              <span>{{ n.title }}</span>
            </div>
            <div class="date">{{ n.createDate }}</div>
            <div class="member">{{ n.memberName }}</div>
          </div>
        </li>
        <div class="page" v-if="state.pagination && state.pagination.lastPageNo > 1">
          <Pagination :info="state.pagination" :change="load"/>
        </div>
      </template>
      <template v-else>
        <li class="empty">
          <div class="title">데이터가 없습니다.</div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import Pagination from "@/components/Pagination";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageMyPageNews";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {Pagination},
  props: {
    menu: Object
  },
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "최근 소식");

      for (let i = 0; i < 5; i += 1) {
        state.news.push({
          projectName: "Wait man",
          projectTypeName: "Wait",
          title: "Hello, We're sorry, but could you please wait a moment?",
          createDate: "2021-12-09",
          memberName: "Wait man"
        });
      }

      load();
    });

    const state = reactive({
      news: [],
      pagination: null,
      loaded: false
    });

    const open = (news, e) => {
      if (!state.loaded || e.target.href) {
        return;
      }

      store.commit("openModal", {
        name: "News",
        params: {
          projectSeq: news.projectSeq,
          projectType: news.projectType,
          newsSeq: news.newsSeq,
          page: "my"
        }
      });
    };

    const load = (num) => {
      const args = {
        pageIndex: num ? num : 1,
        pageSize: 5,
        pageUnit: 5,
      };

      num && window.scrollTo(0, 0);

      state.loaded = false;
      http.get("/api/projects/news", args).then(({data}) => {
        state.loaded = true;
        state.news = data.body.list;
        state.pagination = data.body.paginationInfo;
      });
    };

    return {component, state, open, load};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.mypage";

.my-page-news {
  position: relative;

  ul {
    li {
      position: relative;
      margin-bottom: $px15;
      border: $px1 solid #eee;
      border-radius: $px4;
      padding: $px25 $px25 $px25 $px76;
      cursor: pointer;

      .picture {
        position: absolute;
        top: $px25;
        left: $px15;

        .img {
          width: $px47;
          height: $px47;
          border: $px1 solid #eee;
          background-size: auto 100%;
          border-radius: 50%;
        }
      }

      > .wrapper {
        position: relative;

        .project {
          margin-bottom: $px5;

          span:not(.img) {
            display: inline-block;
            vertical-align: middle;
            margin-right: $px6;

            &.badge {
              padding-top: $px4;
              padding-bottom: $px4;
            }

            &.name {
              max-width: $px300;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .img {
            width: $px16;
            height: $px16;
          }
        }

        .title {
          color: #767676;
        }

        .date {
          position: absolute;
          top: 0;
          right: 0;
          color: #767676;
          font-size: $px13;
        }

        .member {
          position: absolute;
          top: $px25;
          right: 0;
          color: #767676;
          font-size: $px13;
        }
      }

      &.empty {
        text-align: center;
      }
    }
  }

  .page {
    margin-top: $px35;
  }

  &:not(.skeleton) {
    ul li:not(.empty) {
      transition: background 0.18s;

      &:hover {
        background: $colorBackground;
      }
    }
  }

  &.skeleton {
    .nav li a {
      @include skeleton;
    }

    ul li {
      .picture .img {
        @include skeleton;
      }

      .wrapper {
        .project > span,
        .title > span,
        .date,
        .member {
          @include skeleton;
        }

        .project > span.badge {
          display: none;
        }
      }
    }
  }

  @media(min-width: 992px) {
    .my-page-news ul li > .wrapper {
      padding-right: $px120;
    }
  }

  @media(max-width: 991px) {
    ul li {
      padding: $px15 $px15 $px15 $px70;

      .picture {
        top: $px15;
        left: $px10;
      }

      .wrapper {
        .project span:not(.img).name {
          display: block;
          margin-top: $px8;
        }

        .date {
          top: $px2;
        }

        .member {
          position: static;
          margin-top: $px8;
        }
      }
    }

    .page {
      margin-top: $px25;
    }
  }
}
</style>